<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action="leftActionProps">
        <arrow-left-icon
          class="arrow-left"
          @click.prevent="leftActionProps.goBack"
        />
      </template>

      <template v-slot:title>
        <h1>{{ $t("forgotPassword.forgot-password") }}</h1>
      </template>
    </AppHeader>

    <main>
      <p class="smaller text-center">{{ $t("forgotPassword.instructions") }}</p>
      <form class="container" @submit.prevent="onSubmit">
        <label>
          {{ $t("login.email") }}<span class="mandatory">*</span>
          <input type="text" v-model="email" />
        </label>
        <button
          :class="['btn btn-primary', !email ? 'btn-grey disable' : 'btn-red']"
        >
          {{ $t("forgotPassword.reset-password") }}
        </button>
      </form>
      <Errors :errors="errors" />
    </main>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Errors from "@/components/Errors";
import AppHeader from "@/components/AppHeader";
import ArrowLeftIcon from "vue-material-design-icons/ArrowLeft.vue";
import { getParams } from "@/mixins/platform-params.js";
import Spinner from "@/components/Spinner";

export default {
  name: "ForgotPassword",
  mixins: [getParams],
  components: { Errors, AppHeader, ArrowLeftIcon, Spinner },
  data() {
    return {
      email: null
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    next();
  },

  computed: {
    ...mapState(["errors", "loading"])
  },

  methods: {
    ...mapActions("auth", ["forgotPassword"]),
    ...mapMutations(["clearErrors"]),
    onSubmit() {
      this.forgotPassword(this.email).then(response => {
        if (response) {
          this.$router.push({
            name: "login",
            query: {
              platform: this.platform,
              device: this.device
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  padding: 0 40px;

  p {
    color: $black-one;
    margin: 16px 0 27px;
  }

  button {
    margin-top: 20px;
  }
}
</style>
